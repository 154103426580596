// UI
import ParagraphWithTooltip from "../ParagraphWithTooltip/ParagraphWithTooltip"

import {
  formatDataInfoDate,
  displayString,
  formatAmounts,
  formatToFloat,
  formatMinimumInvestment,
} from "../utilities/functions"

function FundDetailsGeneralForeing({ data }) {

  return (
    <section>
      <h1 className="overview-section-title">Información General</h1>
      <div className="overview-section-grid">
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Objetivo de inversión</h2>
          {data.fund.objective ? (
            <ParagraphWithTooltip text={data.fund.objective} />
          ) : (
            <p className="overview-section-text">Sin información</p>
          )}
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Información del Fondo</h2>
          <div className="overview-section-text overview-detail-list">
            {data.fund.fundId && (
              <div className="overview-detail-row">
                <p>Id del Fondo</p>
                <p>{data.fund.fundId}</p>
              </div>
            )}
            {data.fund.asset_class && (
              <div className="overview-detail-row">
                <p>Clase de activo</p>
                <p>{data.fund.asset_class}</p>
              </div>
            )}
            {data.fund.currency && (
              <div className="overview-detail-row">
                <p>Moneda</p>
                <p>{data.fund.currency}</p>
              </div>
            )}
            {data.fund.category && (
              <div className="overview-detail-row">
                <p>Categoría</p>
                <p>{data.fund.category}</p>
              </div>
            )}
            {data.fund.aum && (
              <div className="overview-detail-row">
                <p>Patrimonio del fondo</p>
                <p>{data.fund.currency} {formatAmounts(+data.fund.aum)}</p>
              </div>
            )}
            {data.fund.risks && (
              <div className="overview-detail-row">
                <p>Riesgos</p>
                <p>{data.fund.risks}</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">
            Características Serie{" "}
            {displayString(data.shareClass.shareClassName)}
          </h2>
          <div className="overview-section-text overview-detail-list">
            {data.fund.entryRequirements !== null && (
              <div className="overview-detail-row">
                <p>Requisitos de ingreso</p>
                <ParagraphWithTooltip
                  text={data.fund.entryRequirements}
                  length={50}
                  justify={false}
                />
              </div>
            )}
            {data.fund.minimumInvestmentClp !== null && (
              <div className="overview-detail-row">
                <p>Monto mínimo de inversión</p>
                <p>{formatMinimumInvestment(data.fund.minimumInvestment)}</p>
              </div>
            )}
            {data.shareClass.maxRedemptionPaymentTime !== null && (
              <div className="overview-detail-row">
                <p>Rescates</p>
                <p>{data.shareClass.maxRedemptionPaymentTime}</p>
              </div>
            )}
            {data.shareClass.tac !== null && (
              <div className="overview-detail-row">
                <p>
                  TER{" "}
                  {data.fund.fundType === "FI" ? " del fondo" : " de la serie"}
                </p>
                <p>{`${formatToFloat(data.shareClass.tac)}`}</p>
              </div>
            )}
            <div className="overview-detail-row">
              <p>Nombre Serie</p>
              <p>{data.shareClass.instance.shareClassGeneralInfo.shareClassName}</p>
            </div>
            <div className="overview-detail-row">
              <p>Hedged</p>
              <p>{data.shareClass.instance.shareClassGeneralInfo.hedged !== null ? (data.shareClass.instance.shareClassGeneralInfo.hedged ? "Si" : "No") : "-"}</p>
            </div>
            <div className="overview-detail-row">
              <p>Tipo Inversionista</p>
              <p>{data.shareClass.instance.shareClassGeneralInfo.investorType}</p>
            </div>
            <div className="overview-detail-row">
              <p>Distribuidores Locales</p>
              <p>{data.shareClass.instance.fundGeneralInfo.localDistributors}</p>
            </div>
            {data.shareClass.benefits !== null ||
              (data.shareClass.benefits !== "" && (
                <div className="overview-detail-row">
                  <p>BeneficiosTributarios</p>
                  <p>{data.shareClass.benefits}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Administración</h2>
          <div className="overview-section-text overview-detail-list">
            {/* {data.fund.entryRequirements && (
              <div className="overview-detail-row">
                <p>Requisitos de ingreso</p>
                <p>{data.fund.entryRequirements}</p>
              </div>
            )} */}
            {data.manager.managerName && (
              <div className="overview-detail-row">
                <p>Administradora</p>
                <p>{displayString(data.manager.managerName)}</p>
              </div>
            )}
            {data.fund.fundInceptionDate && (
              <div className="overview-detail-row">
                <p>Fecha de inicio del fondo</p>
                <p>{formatDataInfoDate(data.fund.fundInceptionDate)}</p>
              </div>
            )}
            {data.shareClass.shareClassInceptionDate && (
              <div className="overview-detail-row">
                <p>Fecha de inicio de la serie</p>
                <p>
                  {formatDataInfoDate(data.shareClass.shareClassInceptionDate)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FundDetailsGeneralForeing
