import { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import es from "date-fns/locale/es";

import { LTTB } from "downsample";
import { max, min } from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { formatReturnsDate } from "../ui/utilities/functions";

import { performanceShapeArray } from "../../propTypes/AppPropTypes";
import useColorScheme from "../../hooks/useColorScheme";
import useCheckPortfolioExists from "../../hooks/useCheckPortfolioExists";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);
const dash = (ctx, emptyDates, value) =>
  emptyDates?.includes(ctx.p0.raw.formattedDate) ? value : [6, 0];

function LineChart({
  performanceData,
  colors,
  downSample,
  emptyDates,
  disableDarkMode,
  isSeriesChart,
  showLegend = true,
}) {
  const portfoliosName = useSelector((state) => state.portfoliosName.data);
  const { portfolioA, portfolioB } = portfoliosName;
  const [colorScheme] = useColorScheme();
  const checkPortfolioExists = useCheckPortfolioExists();
  const portfolioExistenceStatus = checkPortfolioExists();
  const checkColor = () => {
    if (portfolioExistenceStatus.doblePortFolio) {
      return colors;
    }
    if (
      portfolioExistenceStatus.portFolioB &&
      !portfolioExistenceStatus.portFolioA
    ) {
      return [colors[1], colors[1]];
    }
    if (
      portfolioExistenceStatus.portFolioA &&
      !portfolioExistenceStatus.portFolioB
    ) {
      return [colors[0], colors[0]];
    }
    return colors;
  };
  const checkNamePorfolio = (index) => {
    if (isSeriesChart) {
      return performanceData[0].name;
    }

    if (portfolioExistenceStatus.doblePortFolio) {
      return index === 0
        ? portfolioA?.portfolioName
        : portfolioB?.portfolioName;
    }
    if (
      portfolioExistenceStatus.portFolioB &&
      !portfolioExistenceStatus.portFolioA
    ) {
      return portfolioB?.portfolioName;
    }

    return portfolioA?.portfolioName;
  };
  const baseX = useMemo(
    () => ({
      axis: "x",
      grid: {
        color:
          colorScheme === "light" || disableDarkMode
            ? "rgba(241, 245, 249,1)"
            : "rgba(71,85,105,0.6)",
        display: true,
        lineWidth: 1,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        tickLength: 8,
        offset: false,
        borderDash: [],
        borderDashOffset: 0,
        borderWidth: 1,
        borderColor:
          colorScheme === "light" || disableDarkMode
            ? "rgba(241, 245, 249,1)"
            : "rgba(71,85,105,0.6)",
      },
      type: "category",
      ticks: {
        font: {
          size: 10,
        },
        callback: function (value) {
          return format(new Date(value), "MMM yyyy", { locale: es });
        },
        autoSkip: true,
        minRotation: 0,
        maxRotation: 0,
        mirror: false,
        textStrokeWidth: 0,
        textStrokeColor: "",
        padding: 3,
        display: true,
        autoSkipPadding: 3,
        labelOffset: 0,
        minor: {},
        major: {},
        align: "center",
        crossAlign: "near",
        showLabelBackdrop: false,
        backdropColor: "rgba(255, 255, 255, 0)",
        backdropPadding: 2,
        color:
          colorScheme === "light" || disableDarkMode
            ? "rgba(71,85,105,0.6)"
            : "rgba(241, 245, 249,1)",
      },
      display: true,
      offset: false,
      reverse: false,
      beginAtZero: false,
      bounds: "ticks",
      grace: 0,
      title: {
        display: false,
        text: "",
        padding: {
          top: 4,
          bottom: 4,
        },
        color: "#e2e8f0",
      },
      id: "x",
      position: "bottom",
    }),
    [colorScheme],
  );

  const options = useMemo(
    () => ({
      responsive: true,
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      parsing: {
        xAxisKey: "formattedDate",
        yAxisKey: "value",
      },
      pointStyle: "circle",
      pointRadius: 1,
      pointHoverRadius: 3,
      plugins: {
        tooltip: {
          backgroundColor:
            colorScheme === "light" || disableDarkMode ? "#ffffff" : "#0f172a",
          titleColor:
            colorScheme === "light" || disableDarkMode ? "#0f172a" : "#F8FAFC",
          bodyColor:
            colorScheme === "light" || disableDarkMode ? "#0f172a" : "#F8FAFC",
          bodySpacing: 10,
          borderWidth: 2,
          borderColor:
            colorScheme === "light" || disableDarkMode ? "#ffffff" : "#0f172a",
          callbacks: {
            title(context) {
              const fDate = context[0].raw.formattedDate;
              const d = new Date(
                fDate.split("-")[0],
                fDate.split("-")[1] - 1,
                fDate.split("-")[2],
              );
              const formattedDate = formatReturnsDate(d);
              return formattedDate;
            },
            label(context) {
              const label = context.raw.name || "";
              const value = context.raw.value || "";
              return `${label} : ${numeral(value).format("0.00")}%`;
            },
          },
        },
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            color:
              colorScheme === "light" || disableDarkMode
                ? "#64748B"
                : "#94A3B8",
            boxWidth: 14,
            boxHeight: 14,
          },
          display: showLegend,
        },
      },
    }),
    [colorScheme],
  );

  const [chartData, setChartData] = useState({ datasets: [] });
  const [optionsChartData, setOptionsChartData] = useState(options);

  const nullDates = emptyDates;

  const handlerChangeOptionsData = useCallback(
    (minXValue, maxXValue) => {
      let changeScale = {};
      changeScale = {
        ...options,
        scales: {
          x: {
            ...baseX,
            type: "time",
            time: {
              unit: "month",
              displayFormats: {
                month: "MMM yyyy",
              },
            },

            min: minXValue,
            max: maxXValue,
          },
          y: {
            axis: "y",
            grid: {
              color:
                colorScheme === "light" || disableDarkMode
                  ? "rgba(241, 245, 249,1)"
                  : "rgba(71,85,105,0.6)",
              display: true,
              lineWidth: 1,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true,
              tickLength: 8,
              offset: false,
              borderDash: [],
              borderDashOffset: 0,
              borderWidth: 1,
              borderColor:
                colorScheme === "light" || disableDarkMode
                  ? "rgba(241, 245, 249,1)"
                  : "rgba(71,85,105,0.6)",
            },
            type: "linear",
            ticks: {
              font: {
                size: 10,
              },
              minRotation: 0,
              maxRotation: 50,
              mirror: false,
              textStrokeWidth: 0,
              textStrokeColor: "",
              padding: 3,
              display: true,
              autoSkip: true,
              autoSkipPadding: 3,
              labelOffset: 0,
              minor: {},
              major: {},
              align: "center",
              crossAlign: "near",
              showLabelBackdrop: false,
              backdropColor: "rgba(255, 255, 255, 0)",
              backdropPadding: 2,
              color:
                colorScheme === "light" || disableDarkMode
                  ? "#64748B"
                  : "#94A3B8",
              suffix: "%",
              callback: (val) => `${val.toFixed(1)} %`,
            },
            display: true,
            offset: false,
            reverse: false,
            beginAtZero: false,
            bounds: "ticks",
            grace: 0,
            title: {
              display: false,
              text: "",
              padding: {
                top: 4,
                bottom: 4,
              },
              color: "#e2e8f0",
            },
            id: "y",
            position: "left",
          },
        },
      };
      setOptionsChartData(changeScale);
    },
    [colorScheme],
  );

  const createChartDataStructure = useCallback(
    (apiData) => {
      const datasets = [];
      // chart downsample data
      let apiDataDownSample = [];
      if (downSample) {
        apiData?.forEach((returnData, index) => {
          const numberDataPoints = returnData?.data?.length;
          const maxDataPoints = 90;
          if (numberDataPoints >= maxDataPoints) {
            const dataPoints = [];
            const oldDataPoint = {};
            returnData?.data?.forEach((d, i) => {
              dataPoints.push([i, d.value]);
              oldDataPoint[i] = d;
            });

            const result = LTTB(dataPoints, maxDataPoints);
            const downSampleData = [];
            result.forEach((dp) => {
              downSampleData.push(oldDataPoint[dp[0]]);
            });

            apiDataDownSample.push({
              name: checkNamePorfolio(index),
              // returnData?.name === "Portafolio A"
              //   ? portfolioA?.portfolioName
              //   : portfolioB?.portfolioName,
              data: downSampleData,
            });
          } else {
            apiDataDownSample = apiData;
          }
        });
      } else {
        apiDataDownSample = apiData;
      }

      const chartValues = [];
      apiDataDownSample?.forEach((returnData, index) => {
        const chartDataT = [];
        returnData?.data?.forEach((d) => {
          chartValues.push(d.date);
          chartDataT.push({
            formattedDate: d.date,
            date: d.date,
            value: d.value,
            name: checkNamePorfolio(index),
            // returnData?.name === "Portafolio A"
            //   ? portfolioA?.portfolioName
            //   : portfolioB?.portfolioName,
          });
        });
        datasets.push({
          label: checkNamePorfolio(index),
          // returnData?.name === "Portafolio A"
          //   ? portfolioA?.portfolioName
          //   : portfolioB?.portfolioName,
          borderColor: checkColor(),
          // returnData?.name === "Portafolio A" ? colors[0] : colors[1], // checkColor(returnData.name, array),
          backgroundColor: checkColor(),
          // returnData?.name === "Portafolio A" ? colors[0] : colors[1], // checkColor(returnData.name, array),
          data: chartDataT,
          spanGaps: true,
          tension: 0.3,
          pointRadius: 0,
          pointBackgroundColor: colors[index],
          pointBorderColor: colors[index],
          borderWidth: 2,
          segment: {
            borderDash: (ctx) => dash(ctx, nullDates[index], [2, 3]),
          },
        });
      });
      setChartData((prevChartData) => ({ ...prevChartData, datasets }));
      const maxDate = max(chartValues);
      const minDate = min(chartValues);
      if (maxDate && minDate) {
        const maxXValue = new Date(
          maxDate.split("-")[0],
          maxDate.split("-")[1] - 1,
          maxDate.split("-")[2],
        );
        const minXValue = new Date(
          minDate.split("-")[0],
          minDate.split("-")[1] - 1,
          minDate.split("-")[2],
        );
        handlerChangeOptionsData(minXValue, maxXValue);
      }
    },
    [nullDates, colorScheme, portfolioA, portfolioB],
  );

  useEffect(() => {
    createChartDataStructure(performanceData);
    return () => { };
  }, [createChartDataStructure, performanceData, colorScheme]);

  return (
    <div>
      {/* Render the info in your component */}
      {chartData ? (
        <Line
          options={optionsChartData}
          data={chartData}
          colors={checkColor()}
          width={622}
          height={300}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
LineChart.propTypes = {
  performanceData: performanceShapeArray.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  downSample: PropTypes.bool,
  emptyDates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  disableDarkMode: PropTypes.bool,
  isSeriesChart: PropTypes.bool,
};

LineChart.defaultProps = {
  colors: ["#FBBF24", "#14B8A6"],
  downSample: false,
  emptyDates: [[], []],
  disableDarkMode: false,
  isSeriesChart: false,
};

export default LineChart;
