import { useState, useRef, useContext } from "react"

// STORE
import { useDispatch, useSelector } from "react-redux"
import { updateChartsData } from "../store/chartsDataSlice"
import PortfolioGroup from "../utils/PortfolioGroup"


// CLASSES
import PortfolioManagerFactory from "../components/ui/PreviewPanel/PortfolioManagerFactory"

// PRESENTERS
import { formatPortfolioMaxMinProfitAndLossesToChart } from "../utils/MaxMinProfitAndLossesPresenter"

// UTILS
import {
  calculateReturnsStatistics,
  portfolioAcc,
  calculatePortfoliosTotals,
  createReturnsTable,
} from "../utils/ReturnsCalculations"
import {
  parseAPIDate,
  calculateRelativeDateFrom,
  getDifferenceInMonths,
} from "../utils/DateCalculations"
import {
  returnsPeriodMap,
  returnsPeriodMapAPV,
} from "../components/ui/utilities/functions"
import { INITIAL_PERIODLIST } from "../utils/constants"
import { FirebaseContext } from "../firebase"
// SENTRY
import * as Sentry from "@sentry/browser";


const usePrefetchChartsData = () => {
  // CONTEXT
  const firebase = useContext(FirebaseContext)


  // STORE
  const dispatch = useDispatch()
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)

  // STATE
  const [instancesCreated, setInstancesCreated] = useState(null)
  const [periodList, setPeriodList] = useState(INITIAL_PERIODLIST)
  const [portfolioGroup, setPortfolioGroup] = useState(null)


  // REFS
  const ageInMonths = useRef(0)
  const hasLessThanOneYear = useRef(false)

  // HELPERS

  // PRIVATE FUNCTIONS
  const generateChartsData = async (
    portfoliosInstances,
    isAPV,
    portfolios,
    distributionOption,
    baseAmount,
  ) => {
    const chartsData = null
    const portfolioGroup = new PortfolioGroup(isAPV, portfoliosInstances)
    setPortfolioGroup(portfolioGroup)

    if (portfoliosInstances && portfoliosInstances.length > 0) {
      try {
        const accReturns = await portfolioGroup.getAccumulatedReturns()
        const minmax = await portfolioGroup.getMinMaxProfitAndLosses()
        const totals = calculatePortfoliosTotals(portfolios, distributionOption, baseAmount)
        const formattedMaxMinProfitLossesChartData =
          formatPortfolioMaxMinProfitAndLossesToChart(
            minmax,
            totals,
          )
        const exposureData = await portfolioGroup.getExposures()
        const issuerData = await portfolioGroup.getIssuers()
        const tacDataPG = await portfolioGroup.getTac()
        const filteredPeriodList = portfolioGroup.getFilteredPeriodList()
        setPeriodList(filteredPeriodList)
        const shiftedReturns = { ...accReturns }
        const retTPD = portfolioGroup.getReturnTable()
        const returnsTablePortfolioData = retTPD
        const { returnsTableData, screenerTable } = createReturnsTable(portfolios, returnsTablePortfolioData, catalogByFunds, returnsPeriodMap, isAPV, portfoliosInstances, portfolioGroup.getLastDate())

        dispatch(
          updateChartsData({
            performanceChartData: shiftedReturns,
            maxMinProfitLossesChartData: formattedMaxMinProfitLossesChartData,
            portfoliosNumber: portfoliosInstances.length,
            exposureData: [exposureData],
            issuersData: {
              issuers1: issuerData[0],
              issuers2: issuerData[1],
            },
            portfoliosTableReturns: returnsTableData,
            tacData: tacDataPG,
            portfolios,
            nullDates: portfoliosInstances.map((port) => port.nullDates),
            timePeriod: filteredPeriodList[0].value,
            periodList: filteredPeriodList,
            screenerTable,
          }),
        )
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error al capturar los datos de portafolios", err)
      }
      setInstancesCreated(true)
      // setTimePeriod(filteredPeriodList[0].value)
    }

    return chartsData
  }

  const generateChartsDataAPV = async (
    portfoliosInstances,
    isAPV,
    portfolios,
    distributionOption,
    baseAmount,
  ) => {
    const chartsData = null
    const portfolioGroup = new PortfolioGroup(isAPV, portfoliosInstances)
    setPortfolioGroup(portfolioGroup)

    if (portfoliosInstances && portfoliosInstances.length > 0) {

      const accReturns = await portfolioGroup.getAccumulatedReturns()
      const minmax = await portfolioGroup.getMinMaxProfitAndLosses()
      const totals = calculatePortfoliosTotals(portfolios, distributionOption, baseAmount)
      const formattedMaxMinProfitLossesChartData =
        formatPortfolioMaxMinProfitAndLossesToChart(
          minmax,
          totals,
        )
      const exposureData = await portfolioGroup.getExposures()
      const issuerData = await portfolioGroup.getIssuers()
      const tacDataPG = await portfolioGroup.getTac()
      const filteredPeriodList = portfolioGroup.getFilteredPeriodList()
      setPeriodList(filteredPeriodList)
      const shiftedReturns = { ...accReturns }
      const retTPD = portfolioGroup.getReturnTable()
      const returnsTablePortfolioData = retTPD
      const { returnsTableData, screenerTable } = createReturnsTable(portfolios, returnsTablePortfolioData, catalogByFunds, returnsPeriodMapAPV, isAPV, portfoliosInstances, portfolioGroup.getLastDate())

      dispatch(
        updateChartsData({
          performanceChartData: shiftedReturns,
          maxMinProfitLossesChartData: formattedMaxMinProfitLossesChartData,
          portfoliosNumber: portfoliosInstances.length,
          exposureData: [exposureData],
          issuersData: {
            issuers1: issuerData[0],
            issuers2: issuerData[1],
          },
          portfoliosTableReturns: returnsTableData,
          tacData: tacDataPG,
          portfolios,
          nullDates: portfoliosInstances.map((port) => port.nullDates),
          timePeriod: filteredPeriodList[0].value,
          periodList: filteredPeriodList,
          screenerTable,
        }),
      )
      setInstancesCreated(true)
      // setTimePeriod(filteredPeriodList[0].value)
    }

    return chartsData
  }

  // PUBLIC FUNCIONS
  const prefetchChartsData = async (
    portfolios,
    currency,
    isAPV,
    distributionOption = "weight",
    baseAmount = null,
  ) => {
    const portfolioManager = PortfolioManagerFactory.getPortfolioManagerInstance(currency)
    portfolioManager
      .enqueueChange(portfolios, isAPV, firebase)
      .then((result) => {
        if (result.status === "OK") {
          if (isAPV) {
            generateChartsDataAPV(
              result.data,
              isAPV,
              portfolios,
              distributionOption,
              baseAmount,
            )
          } else {
            generateChartsData(
              result.data,
              isAPV,
              portfolios,
              distributionOption,
              baseAmount,
            )
          }
        }
        return null
      })
      .catch((error) => console.error(error))
  }

  return { instancesCreated, prefetchChartsData }
}

export default usePrefetchChartsData
