import { useState, useContext, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { ArrowUpTrayIcon, ClockIcon } from "@heroicons/react/24/outline";
import { updateAnalysisDataConfig } from "../../../store/analysisDataConfigSlice";
import { showNotifications } from "../../../store/notificationsSlice";

// HOOKS
import useTracking from "../../../hooks/useTracking";
import { Fetch, PostFile } from "../../../hooks/useFetch";

// CONTEXT
import { FirebaseContext } from "../../../firebase";

// UTILS
import { toURLString } from "../utilities/functions"
import { updateUser } from "../../../store/userSlice"
// SENTRY
import * as Sentry from "@sentry/browser";


function AnalysisCoverPageSelector() {
  // REF
  const fileInputRef = useRef();
  // TRACK
  const trackEvent = useTracking();
  // CONTEXT
  const firebase = useContext(FirebaseContext);
  // STORE
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig);
  const user = useSelector((state) => state.user.user);
  const urls = useSelector((state) => state.urls);
  const dispatch = useDispatch();
  // console.log("USUARIO: ", user)
  // STATE
  const [validationErr, setValidationErr] = useState(null);
  // const [fileData, setFileData] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userCoverPages, setUserCoverPages] = useState(user?.coverPages);
  const getActiveCover = (coverList) => {
    let active = null;
    if (coverList) {
      coverList.forEach((cover, index) => {
        if (cover.active) active = index;
      });
    }
    return active;
  };
  const getActiveCoverObject = (coverList) => {
    let activeCover = null;
    if (coverList) {
      coverList.forEach((cover) => {
        if (cover.active) activeCover = cover;
      });
    }
    return activeCover;
  };
  const [activeCover, setActiveCover] = useState(() =>
    getActiveCover(userCoverPages),
  );

  // FUNCTIONS
  const updateUserActiveCoverPage = (uid, coverPageId) => {
    //console.log("ACTUALIZANDO COVER PAGE")
    try {
      Fetch(
        `${urls.API_URL}/advisors/${uid}/update_active_cover_page`,
        {
          userId: user?.uid,
          coverPageId: coverPageId,
        },
        { firebase },
        () => {
          trackEvent({
            action: "UPLOAD_COVER_PAGE",
            timestamp: Date.now(),
            type: "CLICK",
            data: "",
          });
        },
      );
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error al cambiar campo:", err)
    }
  };
  const updateAnalysisConfig = (coverURL) =>
    dispatch(
      updateAnalysisDataConfig({
        ...analysisDataConfig.data,
        ...{ coverPage: coverURL },
      }),
    );

  const updateActiveCover = (imageIndex) =>
    userCoverPages.map((image, index) => {
      const currentStatus = image.active;
      let newStatus = false;
      if (imageIndex === index) {
        newStatus = !currentStatus;
        if (!newStatus) {
          setActiveCover(null);
        } else {
          setActiveCover(imageIndex);
        }
      }
      const newImage = { ...image, active: newStatus };
      return newImage;
    });

  const handleActivateCover = (imageIndex) => {
    const newCoverPages = updateActiveCover(imageIndex);
    const activeCoverIndex = getActiveCover(newCoverPages);
    // console.log( "SERIAN NUEVAS PORTADAS: ", newCoverPages )
    // ACTUALIZAR LA LISTA
    setUserCoverPages(newCoverPages);
    // ACTUALIZAR PORTAFOLIO INFO
    updateAnalysisConfig(
      activeCoverIndex == null ? null : newCoverPages[activeCoverIndex].url,
    );

    const activeCoverPage = getActiveCoverObject(newCoverPages);
    updateUserActiveCoverPage(user?.uid, activeCoverPage.id);
    // DISPATCH NUEVA INFO EN EL USUARIO.
    dispatch(updateUser({
      ...user,
      ...{ coverPages: newCoverPages },
    }))
  }

  const uploadImage = async (fileData) => {
    const nameParts = fileData.name.split(".");
    const name = toURLString(nameParts[0]);
    const imageFullName = `${user.uid}_${name}.${nameParts[1]}`;
    const uid = user?.uid;
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileData);
      PostFile({
        url: `${urls.API_URL}/advisors/${uid}/upload_cover_page`,
        formData,
        context: firebase,
        onComplete: (data) => {
          if (data.status === "OK") {
            const newCoverPages = [];
            if (userCoverPages) {
              // RECONSTRUIR LA LISTA ACTUAL DE PORTADAS. ASIGNARLES A TODAS EL ESTADO ACTIVE FALSE.
              userCoverPages.forEach((cover) => {
                const updatedCover = {
                  ...cover,
                  ...{ active: false },
                };
                newCoverPages.push(updatedCover);
              });
            }
            //console.log("DATA DE LA IMAGEN: ", data )
            // A LA NUEVA LISTA DE PORTADAS, AGREGARLE EN PRIMER LUGAR LA PORTADA NUEVA.
            newCoverPages.unshift({
              id: data.id,
              active: true,
              url: data.url,
              imageFullName,
            });
            // ASIGNAR COMO IMAGEN ACTIVA LA QUE SE ACABA DE SUBIR
            setActiveCover(0);
            // ACTUALIZAR LISTA DE PORTADAS PARA EL DESPLIEGUE
            setUserCoverPages(newCoverPages);
            // ACTUALIZAR PORTAFOLIO INFO
            updateAnalysisConfig(data.url);

            const activeCoverPage = getActiveCoverObject(newCoverPages); //
            updateUserActiveCoverPage(user?.uid, activeCoverPage.id); // ACTUALIZAR COVER PAGE IN DB
            // DISPATCH NUEVA INFO EN EL USUARIO.
            dispatch(updateUser({
              ...user,
              ...{ coverPages: newCoverPages },
            }))
          } else {
            dispatch(
              showNotifications({
                show: true,
                status: "warning",
                title: "Lo sentimos",
                message:
                  "No pudimos subir la imagen, por favor inténtelo nuevamente.",
                autoHide: true,
                time: 3500,
              }),
            );
          }
          setIsLoading(false);
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        showNotifications({
          show: true,
          status: "warning",
          title: "Lo sentimos",
          message:
            "No pudimos subir la imagen, por favor inténtelo nuevamente.",
          autoHide: true,
          time: 3500,
        }),
      );
      setIsLoading(false);
    }
  };

  const validateImage = (fileData) => {
    const allowedMimeTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/webp",
    ];
    const maxFileSize = 5000000; // 2MB
    let error = false;
    const message = [];
    if (!allowedMimeTypes.includes(fileData.type)) {
      error = true;
      message.push("Debes subir una imagen en formato .jpg o .png.");
    }
    if (fileData.size > maxFileSize) {
      error = true;
      message.push("El tamaño máximo permitido para la imagen es de 5MB.");
    }
    return { message, error };
  };

  const handleUpload = async (fileData) => {
    if (!fileData) return;
    setValidationErr(null);
    const validation = validateImage(fileData);
    if (validation.error) {
      setValidationErr(validation.message);
    } else {
      await uploadImage(fileData);
    }
    fileInputRef.current.value = null;
  };

  const handleCloseModal = () => {
    setIsLoading(false);
    fileInputRef.current.value = null;
    setShowModal(false);
  };

  useEffect(() => {
    // console.log("USER UPDATED")
    setUserCoverPages(user?.coverPages);
  }, [user]);

  const getActiveImage = () => {
    const imageIndex = getActiveCover(userCoverPages);

    return imageIndex !== null ? (
      <div className="w-[full] h-[160px] mb-4">
        <img
          src={userCoverPages[imageIndex].url}
          alt=""
          className="block w-full h-full object-contain"
        />
      </div>
    ) : null;
  };

  return (
    <div className="w-full">
      <div>
        {getActiveImage()}
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="w-full border border-slate-300 dark:border-slate-700 px-3 py-1 text-xs flex items-center justify-center gap-1 rounded-md hover:bg-slate-300 dark:hover:bg-slate-700"
        >
          <PhotoIcon className="w-4 h-4" />
          Seleccionar portada
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[90] outline-none focus:outline-none isolate">
            <div className="relative my-6 mx-auto max-w-1/2">
              <button
                type="button"
                onClick={() => handleCloseModal()}
                className="z-10 absolute top-0 right-0 w-10 h-10 grid place-content-center"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
              <div className="px-8 py-10 rounded-lg shadow-lg relative flex flex-col gap-8 w-full bg-white dark:bg-slate-800 border border-slate-300 dark:border-transparent outline-none focus:outline-none">
                <div className="w-full">
                  <h3 className="text-xl font-semibold">
                    Agrega una portada a tu propuesta PDF
                  </h3>
                  <p className="text-sm">Utiliza una imagen tamaño carta.</p>
                  <p className="text-sm text-slate-500 dark:text-slate-400">
                    Horizontal(816x1056px) o vertical (1056x816px).
                  </p>
                </div>

                <div className="space-y-4">
                  {activeCover !== null && (
                    <div className="w-full space-y-2">
                      <h4 className="text-sm font-semibold">
                        Tu portada seleccionada
                      </h4>
                      <div className="w-full h-60">
                        <img
                          src={userCoverPages[activeCover].url}
                          alt="Portada activa"
                          className="w-full h-full object-contain"
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full">
                    <h4 className="text-sm font-semibold">
                      {!userCoverPages || userCoverPages.length === 0
                        ? "Carga una portada:"
                        : "Tus portadas:"}
                    </h4>
                    <div className="flex items-start justify-center">
                      {userCoverPages && (
                        <div className="flex items-stretch justify-start gap-2 overflow-auto p-2">
                          {userCoverPages.map((image, index) => (
                            <div className="group relative" key={image.id}>
                              <button
                                onClick={() => handleActivateCover(index)}
                                className={`block w-20 h-20 p-1 border rounded-md ${image.active
                                    ? "border-blue-500 dark:border-blue-400"
                                    : "border-slate-300 dark:border-slate-700"
                                  }`}
                              >
                                <img
                                  src={image.url}
                                  alt="Imagen de portada"
                                  className="w-full h-full object-contain"
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="py-2">
                        <label
                          htmlFor="file-upload"
                          className={`cursor-pointer w-20 h-20 p-1 border rounded-md grid place-content-center bg-transparent border-slate-300 dark:border-slate-700 transition-all duration-300 hover:bg-blue-500 hover:text-slate-50 hover:border-transparent
                                ${isLoading
                              ? "pointer-events-none"
                              : "pointer-events-auto"
                            }`}
                        >
                          <span>
                            {isLoading ? (
                              <ClockIcon className="w-4 h-4 animate-slowSpin" />
                            ) : (
                              <ArrowUpTrayIcon className="w-5 h-5" />
                            )}
                          </span>
                          <input
                            ref={fileInputRef}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={(e) => {
                              e.preventDefault();
                              handleUpload(e.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    {validationErr && (
                      <div className="space-y-1 bg-slate-100 dark:bg-slate-700 p-2 rounded-lg border border-slate-300 dark:border-slate-70 text-xs">
                        <p className="font-semibold">
                          No pudimos cargar tu portada:
                        </p>
                        {validationErr.map((msg, i) => (
                          <p key={`error-de-carga-${i + 1}`}>{msg}</p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-white dark:bg-slate-900" />
        </>
      ) : null}
    </div>
  );
}

export default AnalysisCoverPageSelector;
