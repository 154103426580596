import { useEffect, useState, useContext } from "react";

// STORE
import { useSelector } from "react-redux";

// MODEL
// PORTFOLIO MANAGER
import PortfolioManagerFactory from "./PortfolioManagerFactory";
import PortfolioGroup from "src/utils/PortfolioGroup";

// HOOKS
import useCheckPortfolioExists from "src/hooks/useCheckPortfolioExists";
import useColorCompany from "src/hooks/useColorCompany";
import usePortfolioDates from "src/hooks/usePortfolioDates";

// COMPONENTS
import PreviewSectionBox from "./PreviewSectionBox";
import SectionReturns from "./PreviewSections/SectionReturns";
import MinMaxBarChart from "../../charts/MinMaxBarChart/MinMaxBarChart";
import SectionCompositions from "./PreviewSections/SectionCompositions";
import SectionIssuers from "./PreviewSections/SectionIssuers";
import SectionTAC from "./PreviewSections/SectionTAC";

// HELPERS
import { returnsPeriodMap } from "../utilities/functions";
import { FirebaseContext } from "../../../firebase";
import SectionEquityDebt from "./PreviewSections/SectionEquityDebt";
import { formatExposuresDate } from "../utilities/functions";
import { CURRENCIES_MAP } from "../utilities/dataMaps";

function PreviewSections() {
  const firebase = useContext(FirebaseContext);
  const { getColorsCompany } = useColorCompany();
  const currency = useSelector((state) => state.proposalType.currency);
  const checkPortfolioExists = useCheckPortfolioExists();
  const portfolioExistenceStatus = checkPortfolioExists();
  const { portFolioNameA } = portfolioExistenceStatus;
  const portfolios = useSelector((state) => state.portfolioSelection.data);
  const chartsData = useSelector((state) => state.chartsData);
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  );
  const baseAmount = useSelector(
    (state) => state.portfolioSelection.baseAmount,
  );

  const isAPV = useSelector((state) => state.proposalType.isAPV);
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data);

  // STATE
  const [portfoliosInstances, setPortfoliosInstances] = useState(null);
  const [portfolioGroup, setPortfolioGroup] = useState(null);

  useEffect(() => {
    const portfolioManager =
      PortfolioManagerFactory.getPortfolioManagerInstance(currency);
    portfolioManager
      .enqueueChange(portfolios, isAPV, firebase)
      .then((result) => {
        if (result.status === "OK") {
          setPortfoliosInstances(result.data);
          setPortfolioGroup(new PortfolioGroup(isAPV, result.data));
        }
      })
      .catch((error) => console.error(error));
  }, [currency, portfolios]);

  usePortfolioDates(
    portfoliosInstances,
    portfolioGroup,
    portfolios,
    distributionOption,
    baseAmount,
    catalogByFunds,
    isAPV,
    returnsPeriodMap,
  );

  return (
    chartsData && (
      <div data-id="content" className="w-full pb-10">
        {chartsData?.data?.performanceChartData &&
          chartsData?.data?.periodList && (
            <PreviewSectionBox title="">
              <SectionReturns
                timePeriod={chartsData?.data?.timePeriod}
                chartsData={chartsData?.data}
                periodList={chartsData?.data?.periodList}
                portfolioExistenceStatus={portfolioExistenceStatus}
              />
            </PreviewSectionBox>
          )}

        {chartsData?.data?.exposureSummaryData &&
          Object.keys(chartsData?.data?.exposureSummaryData).length > 0 && (
            <PreviewSectionBox
              title="Resumen de exposiciones: Deuda vs Capitalización"
              subtitle={`Datos al ${formatExposuresDate(chartsData?.data?.exposureSummaryData.fecha)}`}
              className="grid-cols-1"
            >
              <SectionEquityDebt data={chartsData?.data?.exposureSummaryData} />
            </PreviewSectionBox>
          )}

        {chartsData.data?.tacData && (
          <PreviewSectionBox title="Tasa Anual de Costos">
            <SectionTAC
              data={chartsData.data?.tacData}
              portFolioNameA={portFolioNameA}
              portfolios={chartsData.data?.portfolios}
            />
          </PreviewSectionBox>
        )}

        {chartsData.data?.exposureData && (
          <PreviewSectionBox title="Composiciones" showLegend>
            <SectionCompositions data={chartsData.data?.exposureData} />
          </PreviewSectionBox>
        )}
        {chartsData.data?.issuersData && (
          <PreviewSectionBox title="Principales emisores">
            <SectionIssuers
              data={chartsData.data?.issuersData}
              portFolioNameA={portFolioNameA}
              portfolios={chartsData.data?.portfolios}
            />
          </PreviewSectionBox>
        )}

        {chartsData?.data?.maxMinProfitLossesChartData && (
          <PreviewSectionBox title="Máximas pérdidas y ganancias" showLegend>
            {(baseAmount > 0 || distributionOption === "amount") && (
              <p className="text-xs mb-5">
                Inversión en <strong className="font-bold">{CURRENCIES_MAP[currency]}</strong>
              </p>
            )}
            <div className="w-full">
              {chartsData?.data?.maxMinProfitLossesChartData && (
                <MinMaxBarChart
                  data={chartsData?.data?.maxMinProfitLossesChartData}
                  chartOptions={{
                    portfolioNumber: chartsData?.data?.portfoliosNumber,
                  }}
                  colors={getColorsCompany()}
                  distributionOption={distributionOption}
                  baseAmount={baseAmount}
                />
              )}
            </div>
          </PreviewSectionBox>
        )}
      </div>
    )
  );
}

export default PreviewSections;
