import { useState } from "react"
import PropTypes from "prop-types"

// STORE
import { useSelector } from "react-redux"

// HOOKS
import useMyAnalysisFunctions from "./useMyAnalysisFunctions"
import useColorCompany from "../../../../hooks/useColorCompany"

// COMPONENTS
import { AccountBodyBox } from "../AccountComponents"
import MyAnalysisNav from "./MyAnalysisNav"

// UTILS
import {
  displayString,
  formatToFloat,
  formatToInteger,
} from "../../utilities/functions"

function MyAnalysisProposal({ userID, analysisList, updateRawData }) {
  // const baseUrlPublicSite = window.location.origin

  const { getColorPortfolio } = useColorCompany()
  // STORE
  const urls = useSelector((state) => state.urls)

  return (
    <div className="flex flex-col gap-8">
      {analysisList.map((analysis) => (
        <AccountBodyBox key={analysis.aid}>
          <MyAnalysisNav
            proposalID={analysis.aid}
            analysis={analysis}
            webPath={`${urls.SITE_URL}/public-link/${userID}/${analysis.aid}/`}
          />

          <div className="grid grid-cols-2 items-start gap-10">
            {Object.keys(analysis.portfolios).map((portfolio) => {
              const currentPortfolio = analysis.portfolios[portfolio]
              const { portfolioName, portfolioFunds } = currentPortfolio
              return (
                portfolioFunds[0] && (
                  <div key={`${analysis.aid}-${portfolio}`}>
                    <h2 className="flex items-center justify-start gap-2 text-xs mb-4">
                      <span
                        className={`w-3 h-3 rounded-sm `}
                        style={{
                          backgroundColor: `${portfolio === "portfolioA"
                            ? getColorPortfolio("portfolioA")
                            : getColorPortfolio("portfolioB")
                            }`,
                        }}
                      />
                      {portfolioName}
                    </h2>
                    <div className="flex flex-col gap-2 mb-5">
                      {portfolioFunds.map((fund) => (
                        <div
                          key={`${fund.fundId}-${fund.shareClassName}`}
                          className="text-xs grid grid-cols-[1fr,_100px,_80px] items-center gap-1"
                        >
                          <span>{`${displayString(fund.fund_name)} | ${fund.shareClassName
                            }`}</span>

                          <span className="text-right self-end">
                            {fund.amount !== null && `$${formatToInteger(fund.amount)}`}
                          </span>
                          <span className="text-right self-end">
                            {formatToFloat(+fund.weight, 2, "").replace(
                              /[.,]00$/,
                              "",
                            )}
                            %
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )
            })}
          </div>
        </AccountBodyBox>
      ))}
    </div>
  )
}

MyAnalysisProposal.propTypes = {
  userID: PropTypes.string.isRequired,
  analysisList: PropTypes.arrayOf(
    PropTypes.shape({
      aid: PropTypes.string,
      pdf_path_s3: PropTypes.string,
      portfolios: PropTypes.shape,
      portfoliosConfig: PropTypes.shape,
      portfolios_number: PropTypes.number,
      timestamp: PropTypes.instanceOf(Date),
      title: PropTypes.string,
    }),
  ).isRequired,
  updateRawData: PropTypes.func.isRequired,
}

export default MyAnalysisProposal
