import { useRef } from "react"
import PropTypes from "prop-types"
import { CheckIcon } from "@heroicons/react/24/outline"

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"

import { formatAmounts, formatToFloat } from "../utilities/functions"

function PreviewFundSeriesSelector({
  isMenuOpen,
  setIsMenuOpen,
  catalogByFunds,
  selectedSerie,
  handleSerieChange,
}) {
  // REFS
  const panelRef = useRef()

  // HOOKS
  const { filterUnqualifiedSeries } = usePrepareDataForCharts()

  useOnClickOutside(panelRef, () => {
    setIsMenuOpen(false)
  })

  // CALLBACKS
  const formatMinimumInvestment = (value) => {
    let formated = value
    if (!Number.isNaN(+value)) {
      // SI EL VALOR ES NUMERICO
      formated = formatAmounts(+value)
    } else if (value !== "S/I") {
      // SI NO ES NUMERICO Y NO ES S/I ENTONCES, DEBE SER EN DOLAR CON EL FORMATO USD123
      const number = value.slice(3); // QUITAR EL USD.
      if( !isNaN(parseFloat(number)) ){
        // SI EL VALOR DESPUES DEL USD ES NUMERICO FORMATEARLO
        formated = `USD${formatAmounts(number)}`
      } else {
        formated = "S/I";
      }
    }
    return formated
  }

  return (
    <div
      ref={panelRef}
      className={`w-[380px] absolute top-8 right-0 isolate bg-white dark:bg-slate-600 border border-slate-300 dark:border-transparent pl-6 pr-2 py-4 rounded-md z-[9999] ${
        isMenuOpen ? "block" : "hidden"
      }`}
    >
      <table className="table-frame">
        <thead className="table-head">
          <tr>
            <th className="pb-1 pr-2 font-light text-left" scope="col">
              &nbsp;
            </th>
            <th
              className="pb-1 pr-2 font-light text-right w-[80px]"
              scope="col"
            >
              TAC
            </th>
            <th
              className="pb-1 pr-2 font-light text-right w-[80px]"
              scope="col"
            >
              M. Min
            </th>
            <th
              className="pb-1 pr-2 font-light text-center w-[80px]"
              scope="col"
            >
              B. Trib
            </th>
          </tr>
        </thead>
        <tbody>
          {filterUnqualifiedSeries(catalogByFunds).map((fundSerie) => {
            const isSelectedSerie = fundSerie.serie === selectedSerie
            return (
              <tr
                key={fundSerie.serie}
                onClick={() => handleSerieChange(fundSerie.serie)}
                className={`text-xs ${
                  isSelectedSerie
                    ? "text-blue-500"
                    : "text-slate-900 dark:text-slate-50"
                } text-center hover:bg-blue-400 hover:bg-opacity-20`}
              >
                <td className="text-left align-top py-2 pr-2 relative">
                  {isSelectedSerie && (
                    <CheckIcon className="w-3 h-3 text-blue-500 dark:text-blue-400 absolute top-2.5 -left-4" />
                  )}
                  <span>{fundSerie.serie}</span>
                </td>
                <td className="align-top py-2 pr-2 text-right tabular-nums">
                  {formatToFloat(fundSerie.tac)}
                </td>
                <td className="align-top py-2 pr-2 text-right tabular-nums">
                  {formatMinimumInvestment(fundSerie.minimum_investment)}
                </td>
                <td className="align-top py-2 pr-2 text-center">
                  {fundSerie.tax_benefits ? fundSerie.tax_benefits : "-"}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

PreviewFundSeriesSelector.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
   
  catalogByFunds: PropTypes.array,
  selectedSerie: PropTypes.string.isRequired,
  handleSerieChange: PropTypes.func.isRequired,
}

PreviewFundSeriesSelector.defaultProps = {
  catalogByFunds: [],
}

export default PreviewFundSeriesSelector
