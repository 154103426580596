import { DataFrame } from "danfojs"
import ShareClass from "./ShareClass"
import { getWithExpiry, setWithExpiry } from "./CacheManager"
// SETTINGS
import SETTINGS from "../settings/settings"
import { DataFetch } from "../hooks/useFetch"
// SENTRY
import * as Sentry from "@sentry/browser";


// formattedDate?
const fetchReturnsData = async (fundId, shareClassName, isAPV, fundType, firebase, currency) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/share_class/${currency}/${fundType}/${fundId}/${encodeURIComponent(shareClassName)}/${isAPV}/returns`,
      {},
      { firebase },
      "GET"
    )
    // console.log("RETURNS DATA: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchGeneralInfoData = async (fundId, shareClassName, fundType, firebase, currency) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/share_class/${currency}/${fundType}/${fundId}/${encodeURIComponent(shareClassName)}/general_info`,
      {},
      { firebase },
      "GET"
    )
    // console.log("GENERAL INFO: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchDataExposures = async (fundId, fundType, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/fund/${fundType}/${fundId}/exposures`,
      {},
      { firebase },
      "GET"
    )
    // console.log("EXPOSICIONES: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

const fetchDataIssuers = async (fundId, fundType, firebase) => {
  try {
    const responseData = await DataFetch(
      `${SETTINGS.urls.API_URL}/fund/${fundType}/${fundId}/issuers`,
      {},
      { firebase },
      "GET"
    )
    // console.log("ISSUERS: ", responseData)
    return responseData
  } catch (error) {
    throw new Error(error)
  }
}

async function getShareClassData(fundId, shareClassName, isAPV, fundType, firebaseInstance, currency) {
  // get the returns (could be in cache)
  // get the exposures (could be in cache)
  // set it to the cache
  const shareClassCacheKey = fundId + "-" + shareClassName + "-" + currency
  try {
    // Check if is already cached
    const cachedResponse = await getWithExpiry(shareClassCacheKey)
    if (cachedResponse) {
      return cachedResponse
    }
    // It is not cached. We add it
    const response = await Promise.all([
      fetchReturnsData(fundId, shareClassName, true, fundType, firebaseInstance, currency), // real
      fetchReturnsData(fundId, shareClassName, false, fundType, firebaseInstance, currency), // nominal
      fetchGeneralInfoData(fundId, shareClassName, fundType, firebaseInstance, currency),
      fetchDataExposures(fundId, fundType, firebaseInstance),
      fetchDataIssuers(fundId, fundType, firebaseInstance),
    ])

    const realShareClassReturns = response[0]
    const nominalShareClassReturns = response[1]
    const shareClassGeneralInfo = response[2]
    const fundGeneralInfo = shareClassGeneralInfo.fund
    delete shareClassGeneralInfo.fund
    const shareClassExposures = response[3]
    const shareClassIssuers = response[4]
    const shareClassData = {
      returns: {
        "real": realShareClassReturns,
        "nominal": nominalShareClassReturns
      },
      shareClassGeneralInfo: shareClassGeneralInfo,
      exposures: shareClassExposures,
      issuers: shareClassIssuers,
      fundGeneralInfo: fundGeneralInfo,
    }
    // expiry time set to 30 min
    setWithExpiry(shareClassCacheKey, shareClassData, 1000 * 60 * 60 * 0.5)
    return shareClassData
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

class ShareClassFactory {
  /**
   * Turns Json data into Danfojs DataFrame
   * @param {JSON} jsonData - Data frame in Json format
   * @returns {DataFrame} DataFrame
   */
  static prepareData(jsonData) {
    let df = new DataFrame(jsonData)
    df = df.sortValues("date")
    return df.copy()
  }

  static getShareClassReturnsDF(shareClassReturns) {
    const df = ShareClassFactory.prepareData(shareClassReturns)
    return df
  }

  static async getShareClass(fundId, shareClassName, isAPV, fundType, firebaseInstance, currency = "CLP") {
    const shareClassData = await getShareClassData(
      fundId,
      shareClassName,
      isAPV,
      fundType,
      firebaseInstance,
      currency,
    )
    const returnsType = isAPV ? "real" : "nominal"
    const shareClassReturns =
      shareClassData.returns[returnsType]
    const shareClassGeneralInfo =
      shareClassData.shareClassGeneralInfo
    const fundGeneralInfo = shareClassData.fundGeneralInfo
    const returnsDF =
      ShareClassFactory.getShareClassReturnsDF(shareClassReturns)
    const { exposures } = shareClassData
    const { issuers } = shareClassData
    const returnsFirstDay = shareClassReturns[0].date
    const returnsLastDay = shareClassReturns[shareClassReturns.length - 1].date

    return new ShareClass(
      fundId,
      shareClassName,
      returnsFirstDay,
      returnsLastDay,
      returnsDF,
      exposures,
      issuers,
      shareClassGeneralInfo,
      fundGeneralInfo,
    )
  }
}
export default ShareClassFactory
