import { useContext, useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// ROUTES
import { useSelector, useDispatch } from "react-redux";
// import * as ROUTES from "../../../routes/APP_ROUTES";

// HOOKS
// import usePreviewPanel from "../PreviewPanel/usePreviewPanel";
import useTracking from "../../../hooks/useTracking";
import { DataFetch } from "@/hooks/useFetch"
import { FirebaseContext } from "@/firebase"

// UI
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectLabel
} from "@/components/shadcn/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/shadcn/ui/tooltip"


// STORE
import { toggleProposalCurrency } from "../../../store/proposalTypeSlice";
import usePreviewPanel from "../PreviewPanel/usePreviewPanel";
import { Info } from "lucide-react";
import { formatAmount } from "../utilities/functions";
// import { resetPortfolioSelection } from "../../../store/portfolioSelectionSlice";
// import { resetPortfolioComposition } from "../../../store/portfolioCompositionSlice";
// import { resetCharstData } from "../../../store/chartsDataSlice";
// import { resetFilters } from "../../../store/filtersSlice";
// import { resetSearch } from "../../../store/searchSlice";
// import { resetReducer } from "../../../store/reducedDataSlice";
// import { togglePreviewPanel } from "../../../store/portfolioPanelSlice";

function ProposalCurrencyDropdown() {
  const firebase = useContext(FirebaseContext)
  // STORE
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.proposalType.currency);
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls);
  // HOOKS
  const trackEvent = useTracking();
  const { updatePortfolioSelectionByCurrency } = usePreviewPanel()
  // STATE
  const [showModal, setShowModal] = useState(false);
  const [selection, setSelection] = useState(currency); // LA SELECCIÓN ES UN VALOR SEMI TEMPORAL, NO SURTE EFECTO HASTA QUE SE CAMBIE LA SELECCIÓN EN EL STORE
  const [currencyInfo, setCurrencyInfo] = useState(null);

  const updateProposalCurrency = (newCurrency) => {
    const newSelection = newCurrency;
    dispatch(toggleProposalCurrency(newCurrency));
    setSelection(newSelection);
  };

  const handleSelect = (value) => {
    if (value !== currency) {
      const newCurrency = value;
      updateProposalCurrency(newCurrency);
      updatePortfolioSelectionByCurrency(newCurrency);
    }
  };

  const handleProposalConfirm = () => {
    // CLEAR ALL PORTFOLIOS
    const newCurrency = selection;

    updateProposalCurrency(newCurrency);
    setShowModal(false);
    trackEvent({
      action: "CHANGE_CURRENCY_TYPE",
      timestamp: Date.now(),
      type: "SWITCH",
      data: selection,
    });
  };

  const handleProposalCancel = () => {
    const cancelledSelection = selection === "CLP" ? "USD" : "CLP";
    setSelection(cancelledSelection);
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getCurrencyInfo = async () => await DataFetch(`${urls.API_URL}/currency_value`, {}, { firebase }, "get");
        const currencyInfo = await getCurrencyInfo();
        setCurrencyInfo(currencyInfo); // Assuming currencyValue has a 'value' property
      } catch (error) {
        console.error("Error fetching currency value:", error);
      }
    };
  
    fetchData();
  },[])

  return (
    <>
      <div className="flex items-center gap-2">
        <div className="space-y-1">
          <p className="text-xs sr-only">
            Seleccionar moneda
          </p>
          <Select
            value={selection}
            defaultValue="CLP"
            onValueChange={(value) => handleSelect(value)}
          >
            <SelectTrigger className="w-28 h-8 bg-blue-600 border-blue-400 text-primary-foreground uppercase text-xs">
              <SelectValue aria-label={selection}>
                {currency}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-[999] bg-blue-600 border-blue-400 text-primary-foreground" align="start">
              <SelectGroup>
                <SelectLabel>Moneda de la Propuesta</SelectLabel>
                <SelectItem
                  className="focus:bg-blue-600 focus:text-primary-foreground"
                  value="CLP"
                >
                  CLP
                </SelectItem>
                <SelectItem
                  className="focus:bg-blue-600 focus:text-primary-foreground"
                  value="USD"
                >
                  USD
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {currencyInfo && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger className="text-primary-foreground/70">
                <Info className="w-5 h-5 shrink-0" />
              </TooltipTrigger>
              <TooltipContent className="z-[99999] bg-muted text-muted-foreground">
                <p><strong>Tipo de cambio:</strong></p>
                <p>$1 USD - ${formatAmount(+currencyInfo.value)} CLP</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[90] outline-none focus:outline-none isolate">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="px-8 py-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent outline-none focus:outline-none space-y-8">
                <div className="flex flex-col items-center justify-start gap-0">
                  <ExclamationTriangleIcon className="w-10 h-10 text-blue-500" />
                  <h3 className="text-xl font-semibold">Advertencia</h3>
                </div>

                <p className="text-center text-sm">
                  Al cambiar el tipo de propuesta, perderás tu selección actual
                  de fondos.
                </p>

                <div className="flex flex-col items-center justify-start gap-1border-t border-solid border-slate-100 dark:border-slate-700 rounded-b space-y-2">
                  <button
                    className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-primary-foreground bg-blue-500 hover:bg-blue-400"
                    type="button"
                    onClick={() => handleProposalConfirm()}
                  >
                    Confirmar
                  </button>
                  <button
                    className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-blue-500 dark:text-blue-400 hover:underline"
                    type="button"
                    onClick={() => handleProposalCancel()}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-white dark:bg-slate-900" />
        </>
      ) : null}
    </>
  );
}

export default ProposalCurrencyDropdown;
