import PropTypes from "prop-types"
// STORE
import { useSelector } from "react-redux"
// HOOKS
import useColorCompany from "../../../hooks/useColorCompany"
import useColorScheme from "../../../hooks/useColorScheme"
// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor"
import { formatToFloat, displayString } from "../../ui/utilities/functions"

// Define the order of the keys explicitly
const timeFrames = [
  "5 Años",
  "3 Años",
  "1 Año",
  "6 meses",
  "3 meses",
  "1 mes",
  "YTD",
]

function AnalysisReturnsTable({ data }) {
  // HOOKS
  const { getColorPortfolio } = useColorCompany()
  const [colorScheme] = useColorScheme()
  // STORE
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)

  const getFundName = (fund, shareClassName) => {
    const excludeSeriesList = ["IN"]
    let name = displayString(fund.fundName)
    if (!excludeSeriesList.includes(fund.fundType)) {
      name = `${displayString(fund.fundName)} | ${shareClassName}`
    }
    return name
  }

  return (
    <div className="space-y-12">
      {Object.keys(data).map((portfolioKey) => {
        const { portfolioName } = portfolios[portfolioKey]
        return (
          <div key={portfolioKey}>
            <div
              className={`w-full flex items-center justify-between gap-4 px-2 py-3 rounded-md
                ${colorScheme === "dark" ? "bg-slate-800" : ""}
              `}
              style={{
                backgroundColor:
                  colorScheme === "light"
                    ? addOpacityToHexColor(getColorPortfolio(portfolioKey), 0.3)
                    : null,
              }}
            >
              <div className="flex items-center gap-2">
                <span
                  className="text-base w-5 h-5 rounded-sm grid place-content-center"
                  style={{
                    backgroundColor: `${getColorPortfolio(portfolioKey)}`,
                  }}
                />
                <p className="text-sm font-bold">
                  {portfoliosName?.[portfolioKey].portfolioName}
                </p>
              </div>
            </div>

            <div className="px-2">
              <table className="table-frame">
                <thead className="table-head text-left text-xs">
                  <tr>
                    <th className="py-5">&nbsp;</th>
                    {timeFrames.map((timeFrame) => (
                      <th
                        key={`${portfolioKey}-${timeFrame}`}
                        className="py-6 text-right"
                      >
                        {timeFrame}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left text-xs font-bold">
                      {portfoliosName?.[portfolioKey].portfolioName}
                    </td>
                    {timeFrames.map((timeFrame) => {
                      const value = data[portfolioKey].portfolioData[timeFrame]
                      return (
                        <td
                          key={`${portfolioKey}-${timeFrame}`}
                          className="py-1 text-right font-bold text-xs tabular-nums"
                        >
                          {formatToFloat(value) || "-"}
                        </td>
                      )
                    })}
                  </tr>
                  {data[portfolioKey].fundsData.map((fund) => {
                    const selectedSerie = portfolios[
                      portfolioKey
                    ].portfolioFunds.filter((f) => f.fundId === fund.fundId)
                    return (
                      selectedSerie.length > 0 && (
                        <tr
                          key={`${portfolioKey}${fund.fundId}${fund.fundName}`}
                        >
                          <td className="py-1 text-left text-xs">
                            {getFundName(fund, selectedSerie[0].shareClassName)}
                          </td>
                          {timeFrames.map((timeFrame) => {
                            const value =
                              fund.returnsByShareClass[
                              selectedSerie[0].shareClassName
                              ][timeFrame]
                            return (
                              <td
                                key={`${portfolioName}-${fund.fundId}-${timeFrame}`}
                                className="py-1 text-right text-xs tabular-nums"
                              >
                                {formatToFloat(value) || "-"}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
    </div>
  )
}
AnalysisReturnsTable.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
}

export default AnalysisReturnsTable
