import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateChartsData } from '../store/chartsDataSlice'
import useTracking from './useTracking'

const useChartDataHandlers = (firstReturnDate, lastReturnDate, setFirstReturnDate, setLastReturnDate) => {
    const dispatch = useDispatch()
    const trackEvent = useTracking()
    const chartsData = useSelector((state) => state.chartsData.data)
    const {timePeriod} = chartsData

    

    // Actualiza el gráfico y el periodo
    const updateChartData = useCallback((newTimePeriod) => {
        const newPeriodList = chartsData.periodList.map((period) => {
            // console.log({period});
            if (period.value === 0) {
                return {key: "Manual", value: 0, date: newTimePeriod}
            }
            return period
        })
        dispatch(
            updateChartsData({
                ...chartsData,
                periodList: newPeriodList,
                timePeriod: 0,
                // performanceChartData: {...chartsData.performanceChartData, 0: shiftedDates}
            })
        )
    }, [chartsData, dispatch])

    // Maneja el cambio de la fecha de inicio
    const handleStartDateChange = useCallback(async (date) => {
        const period = chartsData.periodList.find((p) => p.date[0].getTime() === date.getTime())
        const newTimePeriod = [date, lastReturnDate]
        setFirstReturnDate(date)

        if (date > lastReturnDate) {
            setFirstReturnDate(lastReturnDate)
        }
        trackEvent({
            action: "SELECT_RETURN_START_DATE",
            timestamp: Date.now(),
            type: "CHANGE",
            data: date.toUTCString(),
          })
        updateChartData(newTimePeriod)
    }, [lastReturnDate, setFirstReturnDate, setLastReturnDate, updateChartData])

    // Maneja el cambio de la fecha de finalización
    const handleEndDateChange = useCallback(async (date) => {
        const newTimePeriod = [firstReturnDate, date]
        setLastReturnDate(date)
        trackEvent({
            action: "SELECT_RETURN_FECHA_DE_CORTE",
            timestamp: Date.now(),
            type: "CHANGE",
            data: date.toUTCString(),
          })
        
        if(date < firstReturnDate) {
            setLastReturnDate(firstReturnDate)
        }
        
        // await updateChartData(newTimePeriod)
        dispatch(
            updateChartsData({
                ...chartsData, 
                lastDate: date,
            }))
    }, [firstReturnDate, setLastReturnDate, updateChartData])

    // Maneja el cambio del selector de períodos
    const handleSelector = useCallback(async (event) => {
        const selectedValue = parseInt(event.target.value, 10)
        const timePeriodName = chartsData.periodList.find((p) => p.value === selectedValue).key
        trackEvent({
            action: "SELECT_RETURN_TIME_PERIOD",
            timestamp: Date.now(),
            type: "SELECT",
            data: timePeriodName,
          })
        
        const firstDate = chartsData.periodList.find((p) => p.value === selectedValue).date[0]
        const lastDate = chartsData.periodList.find((p) => p.value === selectedValue).date[1]

        setFirstReturnDate(firstDate)
        setLastReturnDate(lastDate)
        const newPeriodList = chartsData.periodList.map((p) => {
            if (p.value === 0) {
                return {...p, date: [firstDate, lastDate]}
            }
            return p
        }) 


        dispatch(
            updateChartsData({
                ...chartsData, 
                timePeriod: selectedValue,
                periodList: newPeriodList
            }))

        // const returnsData = chartsData?.performanceChartData[selectedValue]
        // await updateChartData(selectedValue, returnsData)

    }, [chartsData, setFirstReturnDate, setLastReturnDate, updateChartData, timePeriod])

    return {
        handleStartDateChange,
        handleEndDateChange,
        handleSelector,
        timePeriod
    }
}

export default useChartDataHandlers