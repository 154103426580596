import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PlusIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

// HOOKS
import { useSelector, useDispatch } from "react-redux";
import usePreviewPanel from "./usePreviewPanel";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useTracking from "../../../hooks/useTracking";
import useColorCompany from "../../../hooks/useColorCompany";

// STORE
import {
  togglePreviewPanel,
  setPreviewPanelWidth,
} from "../../../store/portfolioPanelSlice";
import {
  enablePortfolioB,
  updateActivePortfolio,
} from "../../../store/portfolioSelectionSlice";
import { updateChartsData } from "src/store/chartsDataSlice";

// CONTEXT
import { FirebaseContext } from "../../../firebase";

// UTILS
import * as APP_ROUTES from "../../../routes/APP_ROUTES";

// COMPONENTS
import PreviewSections from "./PreviewSections";
import PreviewSectionsAPV from "./PreviewSectionsAPV";
import PreviewFund from "./PreviewFund";
import PreviewFundDAP from "./PreviewFundDAP";
import PreviewFundContainer from "./PreviewFundContainer";
import PreviewLayout from "./PreviewLayout";
import Button from "../Button/Button";
import PortfolioTotalComparisonAlert from "../PortfolioTotalComparisonAlert/PortfolioTotalComparisonAlert";
import DistributionToggle from "../AnalysisSections/distributionToggle";
import PreviewPortfolioTotals from "./PreviewPortfolioTotals";

// CONSTANTS
import { CURRENCIES_MAP } from "@/components/ui/utilities/dataMaps";

function PreviewPanel() {
  // TRACK
  const trackEvent = useTracking();
  const trackEventWithoutDebounce = useTracking(0);
  // HOOKS
  const { ref } = useScrollPosition();
  const navigate = useNavigate();
  const { getPortfolioInfo, getPortfolioTotal } = usePreviewPanel();
  const { getColorPortfolio } = useColorCompany();
  // REF
  const panelRef = ref;
  // CONTEXT
  const { logAnalyticsEvent } = useContext(FirebaseContext);
  // STORE
  const user = useSelector((state) => state.user.user);
  const portfolios = useSelector((state) => state.portfolioSelection.data);
  const portfolioBEnabled = useSelector(
    (state) => state.portfolioSelection.portfolioBEnabled,
  );
  const panelOpen = useSelector((state) => state.portfolioPanel.isPreviewOpen);
  const panelWidth = useSelector(
    (state) => state.portfolioPanel.previewPanelWidth,
  );
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  );
  const portfoliosName = useSelector((state) => state.portfoliosName.data);
  const isAPV = useSelector((state) => state.proposalType.isAPV);
  const currency = useSelector((state) => state.proposalType.currency);
  const dispatch = useDispatch();
  // STATE
  const [dragOriginArea, setDragOriginArea] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState({
    portfolioA: true,
    portfolioB: true,
  });

  useEffect(() => {
    // este useeffect es para resetear las fechas y periodos cuando los portafolios no tienen fondos
    if (getPortfolioInfo().totalFundsSelected === 0) {
      dispatch(
        updateChartsData({
          timePeriod: null,
          periodList: null,
        }),
      );
    }
  }, [portfolios]);

  // CALLBACKS
  const handleTransitionEnd = () => {
    const currentWidth = panelRef?.current?.offsetWidth;
    if (currentWidth !== panelWidth) {
      dispatch(setPreviewPanelWidth(currentWidth));
    }
  };
  const addPortfolio = () => {
    trackEvent({
      action: "ADD_PORTFOLIO",
      timestamp: Date.now(),
      type: "CLICK",
      data: "portfolioB",
    });
    dispatch(enablePortfolioB(true));
    dispatch(updateActivePortfolio("portfolioB"));
  };

  const refHeaderPanel = ref;

  const shouldShowWarning = () =>
    getPortfolioTotal("portfolioA") !== getPortfolioTotal("portfolioB");

  const getPreviewFund = (fund, fundKey, index, currentPortfolio) => {
    switch (fund.fundType) {
      case "IN":
        return (
          <PreviewFundDAP
            key={fundKey}
            fund={fund}
            fundIndex={index}
            currentPortfolio={currentPortfolio}
            setDragOriginArea={setDragOriginArea}
          />
        );
      default:
        return (
          <PreviewFund
            key={fundKey}
            fund={fund}
            fundIndex={index}
            currentPortfolio={currentPortfolio}
            setDragOriginArea={setDragOriginArea}
          />
        );
    }
  };
  return (
    <div
      ref={panelRef}
      className={`fixed top-[56px] right-0 w-full md:w-[650px] h-screen pb-6
       bg-slate-100 dark:bg-slate-800 border-l border-t border-l-slate-300 dark:border-l-slate-700 border-t-white dark:border-t-slate-900 scrollbar-hide z-[48] transform transition-transform ease-in-out duration-500 sm:duration-700
      ${panelOpen ? "translate-x-0" : "translate-x-full"}
      
      `}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="overflow-y-auto overscroll-contain scrollbar-hide h-[100%] px-5">
        <div
          ref={refHeaderPanel}
          className={`top-0 pb-2  z-[20] ${distributionOption === "amount" &&
            portfolios?.portfolioB?.portfolioFunds?.length > 0 &&
            shouldShowWarning()
            ? "pt-10"
            : "pt-6"
            }`}
        >
          {distributionOption === "amount" &&
            portfolios?.portfolioB?.portfolioFunds?.length > 0 &&
            shouldShowWarning() && (
              <PortfolioTotalComparisonAlert
                shouldShow={shouldShowWarning()}
                positioned
              />
            )}
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-base font-semibold">
              Propuesta de Inversión {isAPV && "APV"} en {CURRENCIES_MAP[currency]}
            </h2>
            <button
              type="button"
              onClick={() => dispatch(togglePreviewPanel(!panelOpen))}
              className="w-8 h-8 text-slate-500 dark:text-slate-400 hover:text-blue-400 grid place-items-center transition-all duration-300"
            >
              <ArrowRightIcon className="w-5 h-5" />
            </button>
          </div>

          <div className="w-full flex items-center justify-end gap-1 mb-2">
            <h3 className="text-xs">Distribución:</h3>
            <DistributionToggle />
          </div>

          <PreviewLayout
            portfolioName={portfoliosName.portfolioA.portfolioName}
            portfolioKey="portfolioA"
            color={getColorPortfolio("portfolioA")}
            count={portfolios?.portfolioA?.portfolioFunds?.length}
          >
            <PreviewFundContainer
              portfolioKey="portfolioA"
              dragOriginArea={dragOriginArea}
            >
              {portfolios.portfolioA.portfolioFunds.length > 0 ? (
                <>
                  {portfolios.portfolioA.portfolioFunds.map((fund, index) => {
                    const fundKey = `${fund.fundId}-${fund.shareClassName}-${index}`;
                    return getPreviewFund(fund, fundKey, index, "portfolioA");
                  })}
                  <PreviewPortfolioTotals portfolioKey="portfolioA" />
                </>
              ) : (
                <p className="text-xs text-center p-3 text-slate-500 dark:text-slate-400 rounded-lg flex items-center justify-start gap-1">
                  <ExclamationCircleIcon className="w-5 h-5" /> Agrega
                  instrumentos desde el catálogo o arrastra desde otros
                  portafolios.
                </p>
              )}
            </PreviewFundContainer>
          </PreviewLayout>

          {portfolioBEnabled && (
            <PreviewLayout
              portfolioName={portfoliosName.portfolioB.portfolioName}
              portfolioKey="portfolioB"
              color={getColorPortfolio("portfolioB")}
              count={portfolios?.portfolioB?.portfolioFunds?.length}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
            >
              <div>
                <PreviewFundContainer
                  portfolioKey="portfolioB"
                  dragOriginArea={dragOriginArea}
                >
                  {portfolios.portfolioB.portfolioFunds.length > 0 ? (
                    <>
                      {portfolios.portfolioB.portfolioFunds.map(
                        (fund, index) => {
                          const fundKey = `${fund.fundId}-${fund.shareClassName}-${index}`;
                          return getPreviewFund(
                            fund,
                            fundKey,
                            index,
                            "portfolioB",
                          );
                        },
                      )}
                      <PreviewPortfolioTotals portfolioKey="portfolioB" />
                    </>
                  ) : (
                    <p className="text-xs text-center p-3 text-slate-500 dark:text-slate-400 rounded-lg flex items-center justify-start gap-1">
                      <ExclamationCircleIcon className="w-5 h-5" /> Agrega
                      instrumentos desde el catálogo o arrastra desde otros
                      portafolios.
                    </p>
                  )}
                </PreviewFundContainer>
              </div>
            </PreviewLayout>
          )}

          <div
            className={`flex ${portfolioBEnabled ? "justify-end" : "justify-between"
              } `}
          >
            <button
              type="button"
              className={`text-blue-500 dark:text-blue-400 hover:text-blue-400 dark:hover:text-blue-500 py-1 text-xs flex items-center justify-center gap-1  transition-colors duration-300 ${portfolioBEnabled ? "hidden" : ""
                }`}
              onClick={addPortfolio}
            >
              <PlusIcon className="w-5 h-5" /> Agregar portafolio
            </button>
          </div>
        </div>
        {getPortfolioInfo().totalFundsSelected > 0 ? (
          <div className="w-full pb-20 ">
            {isAPV ? <PreviewSectionsAPV /> : <PreviewSections />}
          </div>
        ) : (
          <div className="w-full text-slate-50 py-4">
            <h2 className="mb-4 text-base">No hay fondos seleccionados</h2>
            <p className="text-xs text-slate-500 dark:text-slate-400 flex items-start justify-start gap-1">
              <ExclamationCircleIcon className="w-5 h-5" />
              Utiliza el catálogo de fondos y agrégalos a un portafolio para
              comenzar a previsualizar la propuesta.
            </p>
          </div>
        )}
      </div>
      {getPortfolioInfo().totalFundsSelected > 0 && (
        <div className="w-full absolute bottom-12 px-5 pt-5 pb-7 bg-slate-100 dark:bg-slate-800 text-center">
          <Button
            size="large"
            label="Ver propuesta"
            transparent={false}
            className="w-full h-12 text-xs uppercase font-semibold text-slate-50 bg-blue-500 hover:bg-blue-400 hover:text-slate-50"
            action={(e) => {
              e.preventDefault();
              trackEventWithoutDebounce({
                action: "REDIRECT_TO_ANALYSIS",
                timestamp: Date.now(),
                type: "CLICK",
                data: "",
              });
              logAnalyticsEvent("select_content", {
                content_type: "Configurar propuesta",
                username: `${user.name} ${user.lastname}`,
                company: user.company,
              });
              setTimeout(() => {
                navigate(APP_ROUTES.ANALYSIS);
              }, 10);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PreviewPanel;
